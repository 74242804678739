<template>
  <Teleport to="#desktop-teleport">
    <div id="desktop-base-with-menu">
      <router-view></router-view>
    </div>
  </Teleport>
</template>

<script>
import Teleport from 'vue2-teleport'

export default {
  components: {
    Teleport,
  },
}
</script>
